<template>
    <div :class="locationInputClass" class="location-input">
        <div class="location-input__autocomplete">
            <svg-icon name="location" class="location-input__location-icon" />
            <input
                ref="autocompleteInput"
                v-model="placeDescription"
                type="text"
                class="input"
                :placeholder="placeholder"
                @focus="loadGoogleMaps"
                @blur="handleInputBlur"
                @keypress="handleKeyPress"
                @input="getPlacePredictions"
            />
            <button
                v-if="showGeolocationButton"
                :disabled="isGeolocating"
                type="button"
                class="location-input__find-my-position"
                tabindex="-1"
                @click="isGeolocated ? clearLocation() : findUserPosition()"
            >
                <template v-if="isGeolocated">
                    <svg-icon name="close" class="close__icon" />
                </template>
                <template v-else>
                    <svg-icon name="near_me" />
                    <span>
                        {{ nearMeLabel }}
                    </span>
                </template>
            </button>
        </div>

        <div v-if="arePredictionsVisible" class="location-input__predictions">
            <div class="location-input__predictions__wrapper">
                <div class="location-input__predictions__list">
                    <ul>
                        <li
                            v-for="(prediction, index) in predictions"
                            :id="`autocompleteOption${index}`"
                            :key="index"
                            @mousedown="getPlace(prediction.place_id)"
                        >
                            <div class="location-input__predictions__item">
                                <svg-icon
                                    v-if="predictionListItemIcon"
                                    :name="predictionListItemIcon"
                                    class="location-input__predictions__item-icon"
                                />
                                <!-- eslint-disable-next-line vue/no-v-html -->
                                <span v-html="getFormattedPrediction(prediction, index)" />
                            </div>
                        </li>
                    </ul>
                    <template v-if="predictions.length > 0">
                        <a
                            v-if="showEnterAddressManuallyTrigger"
                            class="location-input__predictions__enter-address-manually"
                            @mousedown.prevent="handleEnterAddressManually"
                        >
                            {{ $t('search_form.location_enter_address_manually') }}
                        </a>
                        <img
                            v-if="predictions.length > 0"
                            class="location-input__predictions__powered-by-google"
                            src="~/assets/images/powered_by_google.png"
                            alt="Powered by Google"
                            width="111"
                            height="14"
                        />
                    </template>
                    <div v-else-if="placeDescription" class="location-input__predictions__no-results">
                        {{ $t('search_form.location_predictions_no_results') }}
                    </div>
                </div>

                <div v-if="!alwaysPopup" class="location-input__predictions__countries">
                    <div class="location-input__predictions__countries-header">
                        <p>{{ $t('search_form.location_predictions_countries_title') }}</p>
                        <svg-icon name="lightbulb_info" />
                    </div>

                    <ul id="sortedCountries">
                        <li v-for="code in sortedCountries" :key="code">
                            {{ $t(`localization.countries.${code}`) }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { allowedAssetCountryCodes } from '~/config/countryCodeLists';
import { isGeolocatedKey } from '~/config/filters';

export default {
    props: {
        value: {
            type: Object,
            default: null,
        },
        alwaysPopup: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default() {
                return this.$t('search_form.location_placeholder_default', {
                    siteCountry: this.$t(`localization.countries.${this.$store.getters.siteCountryCodeWithFallback}`),
                });
            },
        },
        nearMeLabel: {
            type: String,
            default() {
                return this.$t('search_form.location_near_me');
            },
        },
        showGeolocationButton: {
            type: Boolean,
            default: true,
        },
        showEnterAddressManuallyTrigger: {
            type: Boolean,
            default: false,
        },
        resultTypes: {
            type: Array,
            default: () => ['geocode'],
            description:
                'Google Maps API result types. Default is `geocode` which returns all results. `address` returns address results etc.',
        },
        predictionListItemIcon: {
            type: String,
            default: null,
        },
        hasError: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        let initialDescription = '';

        if (this.value) {
            if (this.value.isGeolocated) {
                initialDescription = this.nearMeLabel;
            } else if (this.value.name) {
                initialDescription = this.value.name;
            }
        }

        return {
            placeDescription: initialDescription,
            isGeolocating: false,
            geolocationError: null,
            predictions: [],
            googleMapsLoaded: false,
            autocompleteSessionToken: null,
            autocompleteService: null,
            arePredictionsVisible: false,
            clickedEnterAddressManually: false,
        };
    },
    computed: {
        ...mapGetters(['isLargeScreen']),
        locationInputClass() {
            return {
                'is-geolocation-error': this.geolocationError !== null,
                'location-input--error': this.hasError,
                'location-input--popup': this.alwaysPopup,
            };
        },
        sortedCountries() {
            return allowedAssetCountryCodes.sort(this.$utils.sortCountriesByLabel);
        },
        isGeolocated() {
            return this.value?.isGeolocated && this.predictions.length === 0;
        },
    },
    watch: {
        value(newLocation) {
            if (newLocation) {
                if (newLocation.name && newLocation.name !== this.placeDescription) {
                    this.placeDescription = newLocation.name === isGeolocatedKey ? this.nearMeLabel : this.value.name;
                }
            } else {
                this.placeDescription = '';
            }
        },
    },
    mounted() {
        if (!this.isLargeScreen && !this.alwaysPopup) {
            this.arePredictionsVisible = true;
        }

        if (this.alwaysPopup) {
            this.loadGoogleMaps();
        }
    },
    methods: {
        clearLocation() {
            this.$emit('input', null);
            this.focusInput();
        },
        focusInput() {
            this.$refs.autocompleteInput.focus();
        },
        async loadGoogleMaps(e) {
            if (this.alwaysPopup && e?.type === 'focus') {
                return;
            }

            if (!this.googleMapsLoaded) {
                await this.$gmapApiPromiseLazy();
                this.googleMapsLoaded = true;
            }

            /* global google */
            this.autocompleteSessionToken = new google.maps.places.AutocompleteSessionToken();
            this.autocompleteService = new google.maps.places.AutocompleteService();

            if (this.isLargeScreen && !this.alwaysPopup) {
                this.arePredictionsVisible = true;
            }

            if (!this.isGeolocated) {
                this.$nextTick(() => {
                    this.getPlacePredictions(false);
                });
            }
        },
        async getPlace(placeId) {
            const geocoder = new google.maps.Geocoder();
            await geocoder.geocode({ placeId }, (result, status) => {
                if (status === 'OK') {
                    this.predictions = [];
                    this.handlePlaceChange(result[0]);
                } else {
                    this.$sentry.captureException(status);
                }
            });
        },
        extractAddressComponentsFromPlace(newPlace) {
            const COUNTRY_TYPE = 'country';
            const CITY_TYPES = ['locality', 'postal_town'];
            const STREET_NAME_TYPE = 'route';
            const STREET_NUMBER_TYPE = 'street_number';
            const ZIP_CODE_TYPE = 'postal_code';

            const addressComponents = {
                country: '',
                city: '',
                streetName: '',
                streetNumber: '',
                zipCode: '',
            };

            newPlace.address_components.forEach(component => {
                if (component.types.includes(COUNTRY_TYPE)) {
                    addressComponents.country = component.short_name;
                }

                if (CITY_TYPES.some(type => component.types.includes(type))) {
                    addressComponents.city = component.long_name;
                }

                if (component.types.includes(STREET_NAME_TYPE)) {
                    addressComponents.streetName = component.long_name;
                }

                if (component.types.includes(STREET_NUMBER_TYPE)) {
                    addressComponents.streetNumber = component.long_name;
                }

                if (component.types.includes(ZIP_CODE_TYPE)) {
                    addressComponents.zipCode = component.long_name;
                }
            });

            return addressComponents;
        },
        handlePlaceChange(newPlace) {
            if (typeof newPlace.geometry === 'undefined') {
                // Place not found. This happens if the user presses Enter in the input box instead
                // of selecting a place from the autocomplete dropdown
                return;
            }

            const coordinates = newPlace.geometry.location;
            this.placeDescription = newPlace.formatted_address;
            const addressComponents = this.extractAddressComponentsFromPlace(newPlace);

            const location = {
                name: this.placeDescription,
                isGeolocated: false,
                lat: coordinates.lat(),
                lng: coordinates.lng(),
                country: addressComponents.country,
                city: addressComponents.city,
                streetName: addressComponents.streetName,
                houseNumber: addressComponents.streetNumber,
                zipCode: addressComponents.zipCode,
            };

            this.$emit('input', location);
        },
        getPlacePredictions(emitValue = true) {
            if (this.placeDescription === '') {
                this.predictions = [];

                if (emitValue) {
                    this.$emit('input', null);
                }

                if (this.alwaysPopup) {
                    this.arePredictionsVisible = false;
                }

                return;
            }

            const request = {
                input: this.placeDescription,
                sessionToken: this.autocompleteSessionToken,
                types: this.resultTypes,
            };

            this.autocompleteService.getPlacePredictions(request, (predictions, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    this.predictions = predictions;

                    if (this.alwaysPopup) {
                        this.arePredictionsVisible = true;
                    }
                } else if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
                    this.predictions = [];
                } else {
                    this.$sentry.captureException(status);
                }
            });
        },
        handleInputBlur() {
            setTimeout(() => {
                this.placeDescription = this.value?.name;
            }, 200);

            this.removePredictions();
        },
        removePredictions() {
            if (this.isLargeScreen || this.alwaysPopup) {
                this.arePredictionsVisible = false;
            } else {
                this.predictions = [];
            }
        },
        findUserPosition() {
            this.isGeolocating = true;
            this.predictions = [];
            this.geolocationError = null;

            const onSuccess = position => {
                const location = {
                    name: isGeolocatedKey,
                    isGeolocated: true,
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };

                this.$emit('input', location);

                this.isGeolocating = false;
                this.placeDescription = this.nearMeLabel;
            };

            const onError = error => {
                this.isGeolocating = false;
                this.geolocationError = error;
                this.placeDescription = '';
            };

            const maximumAge = 300000; // Allow a cached position, max 5 minutes old

            if (!navigator.geolocation) {
                onError('Geolocation disabled or not supported');

                return;
            }

            navigator.geolocation.getCurrentPosition(onSuccess, onError, {
                maximumAge,
            });
        },
        handleKeyPress(event) {
            if (event.key === 'Enter' && event.code === 'Enter') {
                event.preventDefault();

                if (this.predictions.length > 0) {
                    this.getPlace(this.predictions[0].place_id);
                    this.$refs.autocompleteInput.blur();
                }
            }
        },
        getFormattedPrediction(prediction, index) {
            const formattedStringParts = [];
            const matchedSubstrings = prediction.matched_substrings || [];
            let lastBoldedIndex = 0;

            matchedSubstrings.forEach(({ offset, length }) => {
                formattedStringParts.push(
                    prediction.description.substring(lastBoldedIndex, offset),
                    `<strong>${prediction.description.substring(offset, offset + length)}</strong>`
                );
                lastBoldedIndex = offset + length;
            });

            formattedStringParts.push(prediction.description.substring(lastBoldedIndex));

            return formattedStringParts.join('');
        },
        handleEnterAddressManually() {
            this.$emit('enter-address-manually');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/sass/_variables.scss';

.location-input {
    &__autocomplete {
        position: relative;

        .input {
            padding-left: 40px;
        }
    }

    &--error {
        .input {
            border-color: $error;
        }
    }

    &__location-icon {
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        z-index: 1;
    }

    &__find-my-position {
        cursor: pointer;
        color: $primary;
        padding-right: 7px;
        background: $white;
        box-shadow: $white -10px 0px 10px;
        border: none;
        position: absolute;
        right: 2px;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;

        .svg-icon {
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }

        span {
            font-family: $base-font;
            @include font-size(13px);
        }

        &[disabled] {
            color: $disabled;

            .svg-icon {
                animation: rotation 2s infinite linear;
            }
        }
    }

    &.is-geolocation-error {
        .location-input__find-my-position {
            color: $error;
        }
    }

    &--popup {
        position: relative;

        .location-input__predictions {
            position: absolute;
            bottom: -4px;
            transform: translateY(100%);
            border: 1px solid $border-color;
            border-radius: $default-border-radius;
            padding: 16px 24px;
            z-index: 1;
            margin-top: 0;

            &__list {
                max-width: unset;
            }
        }
    }

    &__predictions {
        width: 100%;
        background: $white;
        margin-top: 24px;
        left: 0;
        box-sizing: border-box;

        @include lg {
            width: 787px;
            position: absolute;
            bottom: -16px;
            transform: translateY(100%);
            border: 1px solid $border-color;
            border-radius: $default-border-radius;
            padding: 24px;
            z-index: 1;
            margin-top: 0;
        }

        &__list {
            box-sizing: border-box;
            margin-bottom: 24px;
            width: 100%;
            width: -webkit-fill-available;
            @include font-size(16px);
            text-align: left;

            li {
                display: block;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                cursor: pointer;
                padding: 4px 0 4px 4px;
                margin-left: -4px;
                border-radius: $default-border-radius;

                &:hover {
                    background: $gray-light;
                }
            }

            @include lg {
                margin: 0;
                padding-right: 16px;
                max-width: calc(100% - 320px);
            }
        }

        &__item-icon {
            margin-right: 10px;
        }

        &__enter-address-manually {
            display: block;
            margin-top: 24px;
        }

        &__powered-by-google {
            display: block;
            margin-top: 20px;
        }

        &__wrapper {
            @include lg {
                display: flex;
                justify-content: space-between;
            }
        }

        &__countries {
            box-sizing: border-box;
            padding: 16px;
            border: 1px solid $border-color;
            border-radius: $default-border-radius;

            @include lg {
                max-width: 320px;
            }
        }

        &__countries-header {
            display: flex;
            justify-content: space-between;

            p {
                font-weight: bold;
                margin-right: 16px;
            }

            .svg-icon {
                width: 45px;
                height: 45px;
            }
        }
    }

    #sortedCountries {
        list-style: disc;
        list-style-position: inside;
    }
}

@keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
    }
}
</style>
